////// Кнопка вверх //////
document.getElementById('scrollToTop').addEventListener("click", () => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	return false;
});

function scrollToTop() {
	const bottom = 23,
		scrollVal = window.pageYOffset,
		windowHeight = window.innerHeight,
		scrollBtn = document.getElementById('scrollToTop'),
		footerOffset = document.getElementsByClassName('footer-simple')[0].offsetTop;

	if(scrollVal + windowHeight > footerOffset) {
		scrollBtn.style.bottom = bottom + scrollVal + windowHeight - footerOffset + 'px';
	}
	else if(parseInt(scrollBtn.style.bottom) > bottom) {
		scrollBtn.style.bottom = bottom + 'px';
	}
}

window.onload = window.onresize = window.onscroll = () => {
	scrollToTop()
}
////// end: //////
